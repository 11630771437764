<template>
  <div class="mb-6">
    <div class="tab-wrapper-primary">
      <div v-for="p in principals" :key="p.id">
        <div v-if="p.id == principal_id" class="tab-heading-primary underline flex">
          <a
            :href="`https://${p.principal_shortname}.resrequest.com/`"
            target="_blank"
            class="opacity-80 hover:opacity-100 flex items-center"
          >
            {{ changeCase(p.name, ElementE.TITLE) }}
            <LinkOutIcon
              class="pl-2"
            />
          </a>
        </div>
      </div>
      <div class="p-6">
        <SourceMenu
          :key="principal_id"
          :sources="sourceArray"
          tab="propery"
          @input="sourceUpdate"
          label="Create Booking with Source Code"
          :additional="principal_id"
          defaultSelect="Not specified"
          :values="sources"
        />

        <div class="h-px my-4 border border-dashed border-gray-300"></div>

        <p class="mb-4 text-sm font-medium">Select the properties for the widget</p>

        <div v-for="p in principals" :key="p.id">
          <div v-if="p.id == principal_id" class="flex flex-col gap-4">
            <div v-for="item in p.properties" :key="item.id">
              <TwinSelect
                @updateWidget="updateWidget"
                :title="item.name"
                :id="item.id"
                :widgets="widget"
                :principalId="p.id"
                :principals="principals"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TwinSelect from "@/components/inputs/TwinSelect.vue";
import SourceMenu from "@/components/inputs/SourceMenu";
import LinkOutIcon from "../icons/LinkOutIcon.vue";
import { changeCase } from "../../helpers";
import { ElementE } from '../../enums'

export default {
  name: "PropertyBlock",
  emits: ["updateWidget"],
  components: { TwinSelect, SourceMenu, LinkOutIcon },
  props: {
    sources: {
      type: Object,
    },
    principals: {
      type: Object,
    },
    principal_id: {
      type: String,
    },
    widgets: {
      type: Object,
    },
  },
  data() {
    return {
      widget: {},
      sourceArray: {},

      changeCase: changeCase,
      ElementE: ElementE,
    };
  },
  methods: {
    sourceUpdate(ids){
      const idsArray = ids.split("|");
      const sourceId = idsArray[0];
      const principalId = idsArray[1]

      if(this.widget.config.principal_source_maps === undefined){
        this.widget.config.principal_source_maps = [];
      }

      if(this.widget.config.principal_source_maps.length !== 0){

        this.widget.config.principal_source_maps =
          this.widget.config.principal_source_maps.filter((item) => item.principal_id !== principalId)

        this.widget.config.principal_source_maps.push(
          {
            "principal_id": principalId,
            "source_id": sourceId
          }
        )

      }else{
        this.widget.config.principal_source_maps.push(
          {
            "principal_id": principalId,
            "source_id": sourceId
          }
        )
      }

      this.updateWidget();
    },

    updateWidget() {
      this.$emit("updateWidget", this.widget);
    },
  },
  mounted() {
    this.widget = this.widgets;
    this.sourceArray = this.widget.config.principal_source_maps;
  },
  watch: {
    widgets: function (widgets) {
      this.widget = widgets;
      if(this.widget.config !== undefined && this.widget.config.principal_source_maps !== undefined){
        this.sourceArray = this.widget.config.principal_source_maps;
      }
    },
  },
};
</script>
