
import { defineComponent } from "vue";

import { IColorObject, HtmlEvent, WidgetI } from "@/interfaces/interfaces";
import { changeCase } from "../../helpers";
import { ElementE, DropdownTypeE } from '../../enums'
import { iconsList } from '@/components/edittabs/constants';

import SelectColor from "../SelectColor.vue";
import NotificationPopup from "@/components/notifications/NotificationPopup.vue";
import BaseTooltip from '../notifications/BaseTooltip.vue';
import TooltipIcon from "../icons/TooltipIcon.vue";
import DropdownMenu from '@/components/inputs/DropdownMenu.vue';

export default defineComponent({
  name: "StyleTab",
  props:['widgets'],
  emits:['updateWidget'],
  components: {
    SelectColor,
    NotificationPopup,
    BaseTooltip,
    TooltipIcon,
    DropdownMenu,
  },
  data() {
    return {
      isShowSavePopup: false,
      widget: {} as WidgetI,
      custom_css: '' as string,
      changeCase: changeCase,
      ElementE: ElementE,
      iconsList: iconsList,
      DropdownTypeE,
    };
  },
  mounted() {
    this.widget = this.widgets;
    this.custom_css = this.widget.custom_css
  },
  watch: {
    widgets: function (widgets) {
      this.widget = widgets
      this.custom_css = this.widget.custom_css
    }
  },
  methods: {
    setCustomCss(event: HtmlEvent) {
      let custom_css_string = event.target.value;
      this.widget.custom_css = custom_css_string;
      this.isShowSavePopup = true;
      this.$emit('updateWidget', this.widget);
    },
    cnangeIcon(e: string) {
      this.widget.config.deal_icon_colour = e;
      this.isShowSavePopup = true;
      this.$emit('updateWidget', this.widget);
    },
    setColor(colorObject: IColorObject, key: string) {
      const obj = { [key]: colorObject.color };
      this.widget.config = {...this.widget.config, ...obj};
      this.isShowSavePopup = true;
      this.$emit('updateWidget', this.widget);
    },
  },
});
