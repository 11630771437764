import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "style_tab",
  class: "my-6"
}
const _hoisted_2 = { class: "tab-wrapper-primary" }
const _hoisted_3 = { class: "tab-heading-primary" }
const _hoisted_4 = { class: "p-6" }
const _hoisted_5 = { class: "relative grid grid-cols-2 gap-4 pb-4" }
const _hoisted_6 = { class: "grid grid-cols-2 gap-4 py-4 border-t-[1px]" }
const _hoisted_7 = { class: "grid grid-cols-2 gap-4 pt-4 border-t-[1px] border-dashed" }
const _hoisted_8 = { class: "w-full pt-2 mt-4" }
const _hoisted_9 = { class: "relative" }
const _hoisted_10 = {
  class: "label-primary flex gap-1",
  for: ""
}
const _hoisted_11 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationPopup = _resolveComponent("NotificationPopup")!
  const _component_SelectColor = _resolveComponent("SelectColor")!
  const _component_DropdownMenu = _resolveComponent("DropdownMenu")!
  const _component_TooltipIcon = _resolveComponent("TooltipIcon")!
  const _component_BaseTooltip = _resolveComponent("BaseTooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (this.widget.edited)
      ? (_openBlock(), _createBlock(_component_NotificationPopup, {
          key: 0,
          status: "warning",
          title: "Changes Unpublished",
          text: "To view your updates on the widget, please save and publish them.",
          class: "mb-6"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.changeCase('customise design', _ctx.ElementE.TITLE)), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_SelectColor, {
            value: _ctx.widget.config?.link_style,
            label: "Link colour",
            onColors: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setColor($event, 'link_style')))
          }, null, 8, ["value"]),
          _createVNode(_component_SelectColor, {
            value: _ctx.widget.config?.link_hover_colour,
            label: "Link hover colour",
            onColors: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setColor($event, 'link_hover_colour')))
          }, null, 8, ["value"]),
          _createVNode(_component_SelectColor, {
            value: _ctx.widget.config?.button_colour,
            label: "Button colour",
            onColors: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setColor($event, 'button_colour')))
          }, null, 8, ["value"]),
          _createVNode(_component_SelectColor, {
            value: _ctx.widget.config?.button_hover_colour,
            label: "Button hover colour",
            onColors: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setColor($event, 'button_hover_colour')))
          }, null, 8, ["value"]),
          _createVNode(_component_SelectColor, {
            value: _ctx.widget.config?.button_text_colour,
            label: "Button text colour",
            onColors: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setColor($event, 'button_text_colour')))
          }, null, 8, ["value"]),
          _createVNode(_component_SelectColor, {
            value: _ctx.widget.config?.button_hover_text_colour,
            label: "Button hover text colour",
            onColors: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setColor($event, 'button_hover_text_colour')))
          }, null, 8, ["value"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_SelectColor, {
            value: _ctx.widget.config?.deal_btn_bg_colour,
            "is-change-case": false,
            label: "“Deals for you” button colour",
            onColors: _cache[6] || (_cache[6] = ($event: any) => (_ctx.setColor($event, 'deal_btn_bg_colour')))
          }, null, 8, ["value"]),
          _createVNode(_component_SelectColor, {
            value: _ctx.widget.config?.deal_btn_text_colour,
            "is-change-case": false,
            label: "“Deals for you” button text colour",
            onColors: _cache[7] || (_cache[7] = ($event: any) => (_ctx.setColor($event, 'deal_btn_text_colour')))
          }, null, 8, ["value"]),
          _createVNode(_component_SelectColor, {
            value: _ctx.widget.config?.deal_btn_hover_colour,
            "is-change-case": false,
            label: "“Deals for you” button hover colour",
            onColors: _cache[8] || (_cache[8] = ($event: any) => (_ctx.setColor($event, 'deal_btn_hover_colour')))
          }, null, 8, ["value"]),
          _createVNode(_component_SelectColor, {
            value: _ctx.widget.config?.deal_btn_text_hover_colour,
            "is-change-case": false,
            label: "“Deals for you” button hover text colour",
            onColors: _cache[9] || (_cache[9] = ($event: any) => (_ctx.setColor($event, 'deal_btn_text_hover_colour')))
          }, null, 8, ["value"]),
          (_ctx.widget.config?.deal_icon_colour)
            ? (_openBlock(), _createBlock(_component_DropdownMenu, {
                key: 0,
                values: _ctx.iconsList,
                label: "“Deals for you” icon",
                "default-select": _ctx.widget.config?.deal_icon_colour || 'Choose icon',
                "is-change-case": false,
                type: _ctx.DropdownTypeE.ICON,
                onSelect: _ctx.cnangeIcon
              }, null, 8, ["values", "default-select", "type", "onSelect"]))
            : _createCommentVNode("", true),
          _createVNode(_component_SelectColor, {
            value: _ctx.widget.config?.deal_matrix_icon_colour,
            "is-change-case": false,
            label: "“Deals for you” you icon colour (in matrix-cells)",
            onColors: _cache[10] || (_cache[10] = ($event: any) => (_ctx.setColor($event, 'deal_matrix_icon_colour')))
          }, null, 8, ["value"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_SelectColor, {
            value: _ctx.widget.config?.minlos_bg_colour,
            "is-change-case": false,
            label: "MinLOS banner background colour",
            onColors: _cache[11] || (_cache[11] = ($event: any) => (_ctx.setColor($event, 'minlos_bg_colour')))
          }, null, 8, ["value"]),
          _createVNode(_component_SelectColor, {
            value: _ctx.widget.config?.minlos_text_colour,
            "is-change-case": false,
            label: "MinLOS banner text colour",
            onColors: _cache[12] || (_cache[12] = ($event: any) => (_ctx.setColor($event, 'minlos_text_colour')))
          }, null, 8, ["value"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("label", _hoisted_10, [
              _createTextVNode(" Custom CSS "),
              _createVNode(_component_BaseTooltip, { "tooltip-text": "For a sample of the custom CSS code, please refer to the developer notes accessible from the menu in the upper right corner" }, {
                icon: _withCtx(() => [
                  _createVNode(_component_TooltipIcon)
                ]),
                _: 1
              })
            ]),
            _withDirectives(_createElementVNode("textarea", {
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.custom_css) = $event)),
              class: "input-primary h-40",
              placeholder: (_ctx.changeCase('enter code...', _ctx.ElementE.PLACEHOLDER) as string),
              onInput: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.setCustomCss && _ctx.setCustomCss(...args)))
            }, null, 40, _hoisted_11), [
              [_vModelText, _ctx.custom_css]
            ])
          ])
        ])
      ])
    ])
  ]))
}